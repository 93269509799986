<template>
  <div class="report-center-home">
    <div class="wrap">
      <h1 class="text">报表中心</h1>
      <h2 class="sub-text">洞察项目业务现状，协助经营策略优化</h2>
      <div class="search-panel">
        <el-autocomplete
          prefix-icon="el-icon-search"
          v-model="searchValue"
          :fetch-suggestions="querySearch"
          placeholder="请输入报表名称"
          :trigger-on-focus="false"
          @select="toSubPage"
        ></el-autocomplete>
        <ul class="most-visited-list">
          <li
            v-for="item in mostVisitedList"
            :key="item.id"
            @click="toSubPage(item)"
          >{{ item.cnname }}</li>
        </ul>
      </div>
      <ul class="report-list">
        <li v-for="item in reportList" :key="item.id">
          <h1>
            <i :class="item.name | getIconName"></i>
            {{ item.name }}
          </h1>
          <ul>
            <li
              class="ellipsis"
              :title="item1.name"
              v-for="item1 in item.dicVOS"
              :key="item1.id"
              @click="toSubPage(item1)"
            >{{ item1.name }}</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  name: 'reportCenterHome',
  data () {
    return {
      searchValue: '',
      mostVisitedList: [],
      reportList: [],
      searchReportList: []
    }
  },
  computed: {
    url () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://' + document.domain + ':8080'
      }
      return temUrl
    }
  },
  mounted () {
    this.getMostVisitedList()
    this.getReportList()
  },
  filters: {
    getIconName: (name) => {
      let iconName = ''
      switch (name) {
        case '招商分析':
          iconName = 'icon-report-merchants'
          break
        case '营运分析':
          iconName = 'icon-report-service'
          break
        case '财务分析':
          iconName = 'icon-report-finance'
          break
        case '租务分析':
          iconName = 'icon-report-rental'
          break
        default:
          iconName = 'icon-report-merchants'
          break
      }
      return iconName
    }
  },
  methods: {
    getMostVisitedList () {
      const params = {
      }
      this.axios.post(api.getRecentlyOpened, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.mostVisitedList = resData.filter(item => {
            return item
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getReportList () {
      const params = {
      }
      this.axios.post(api.getBBList, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData && resData.length) {
            this.reportList = resData
            for (let i = 0; i < resData.length; i++) {
              for (let j = 0; j < resData[i].dicVOS.length; j++) {
                const item = resData[i].dicVOS[j]
                if (item.url) { // 有权限的才出现在搜索框
                  this.searchReportList.push(
                    {
                      id: item.id,
                      value: item.name,
                      url: item.url
                    }
                  )
                }
              }
            }
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，暂无权限访问此应用模块！'
            })
            this.$router.push({ path: '/' })
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    createMostVisitedList (id) {
      const params = {
        id: id
      }
      this.axios.post(api.getAddRecentlyOpened, params).then(res => {
        if (res.data.code === 0) {
          // const resData = res.data.data
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    toSubPage (item) {
      if (item.url) { // 存在url则有权限否则无
        this.createMostVisitedList(item.id)
        setTimeout(() => {
          window.location.assign(this.url + '/#/reportCenter?url=' + item.url + '&cid=' + item.id)
          if (window.ActiveXObject || 'ActiveXObject' in window) {
            window.location.reload()
          }
        }, 500)
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，暂无权限访问此报表。'
        })
      }
    },
    querySearch (queryString, cb) {
      var restaurants = this.searchReportList
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.report-center-home
  min-height 831px
  overflow hidden
  background url('~@/assets/images/workBench/report-center-bg.png') no-repeat
  background-position 100% -121px
  .wrap
    max-width 1800px
    margin 0 64px
  .text
    margin-top 54px
    font-size 28px
    font-weight bold
    color #FFFFFF
  .sub-text
    margin-top 15px
    font-size 18px
    font-weight 400
    color #FFFFFF
  .search-panel>>>
    width 980px
    margin 39px auto
    .el-input__inner
      width 980px
      background-color #272930
      box-shadow 0px 10px 46px 0px rgba(18, 18, 20, 0.61)
      border-radius 3px
    .most-visited-list
      li
        display inline-block
        color rgba(255,255,255,.5)
        font-size 12px
        margin 20px 24px 0 0
        cursor pointer
        &:hover
          text-decoration underline
  .report-list>li
    margin-left 16px
    box-sizing border-box
    &:first-child
      margin-left 0
  .report-list
    width auto
    color #fff
    margin-bottom 35px
    display flex
    justify-content center
    &>li
      width 27%
      background-color #272930
      box-shadow 0px 1px 12px 0px rgba(0, 0, 0, 0.31)
      border-radius 3px
      text-align center
      padding-bottom 3px
      h1
        width 100%
        height 90px
        line-height 90px
        font-size 26px
        font-weight 500
        color #FFFFFF
        background-image url('~@/assets/images/workBench/report-center-bg-item-title.png')
        background-repeat no-repeat
        background-position right top
        background-color #34363d
      ul
        padding 0 26px
        margin-top 32px
        li
          display inline-block
          float left
          width 46%
          height 38px
          line-height 38px
          border 1px dashed rgba(255,255,255,.2)
          margin 0 24px 26px 0
          cursor pointer
          font-size 14px
          &:hover
            border-color #FFA134
            color #FFA134
        li:nth-child(2n)
          margin 0 0 26px 0
  .icon-report-rental:before,
  .icon-report-service:before,
  .icon-report-finance:before
    font-size 24px !important
@media screen and (max-width: 1810px)
  .report-list>li
  .report-list
    li
      width 27% !important
      ul
        padding 0 22px !important
        li
          width 46% !important
@media screen and (max-width: 1770px)
  .report-list>li
  .report-list
    li
      width 27% !important
      ul
        padding 0 20px !important
        li
          width 46% !important
          margin 0 20px 20px 0 !important
        li:nth-child(2n)
          margin 0 0 20px 0 !important
@media screen and (max-width: 1700px)
  .report-list
    li
      width 27% !important
      ul
        li
          width 45% !important
@media screen and (max-width: 1440px)
  .report-list
    li
      width 27% !important
      ul
        li
          width 44% !important
</style>
